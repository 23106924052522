import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';

import Box from '@mui/joy/Box';
import Tabs from '@mui/joy/Tabs';
import TabPanel from '@mui/joy/TabPanel';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageIcon from '@mui/icons-material/Message';
import Divider from '@mui/joy/Divider';

import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

import { PanelAccount, PanelMessages, PanelStart } from '../panels';
import MessagesPaneHeaderStart from './MessagesPaneHeaderStart';
import FloatingButton from './FloatingButton';

const ChatBoxAccount = (props) => {
	const {
		agent,
		chatBoxCss,
		chatMessages,
		chatLocalState,
		bubbleAppearanceState,
		bubbleState,
		setBubbleState,
		setChatLocalState,
		handleOtpRequest,
		handleOtpVerify
	} = props;

	const chatBoxRef = useRef(null);
	const chatRef = useRef(null);

	const transitionStyles = {
		entering: { opacity: 0 },
		entered: { opacity: 1 },
		exiting: { opacity: 0 },
		exited: { opacity: 0 }
	};

	const textColor = useMemo(
		() => pickColorBasedOnBgColor(bubbleAppearanceState?.colorPrimary || '#ffffff', '#ffffff', '#000000'),
		[bubbleAppearanceState?.colorPrimary]
	);

	function scrollToBottom() {
		if (!chatRef.current) {
			return;
		}

		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: 'smooth'
		});
	}

	const [placement, setPlacement] = useState('home');

	useEffect(() => {
		// setChatLocalState({
		//   ...chatLocalState,
		//   messageList: [
		//     { message: 'message' },
		//     { message: 'message' },
		//     { message: 'message' },
		//     { message: 'message' },
		//   ],
		// });
		if (chatMessages) {
			setTimeout(scrollToBottom);
		}
	}, [chatMessages]);

	return (
		<Box
			sx={{
				overflow: 'visible',
				// position: 'fixed', Mod
				// position: 'absolute',
				position: chatBoxCss?.containerBox?.position || 'absolute',
				height: '360px',
				// bottom: '70px', Mod
				// bottom: '20px',
				bottom: chatBoxCss?.containerBox?.bottom || '20px',
				zIndex: 999999,

				...(bubbleAppearanceState?.chatBoxPosition === 'left' ? { left: '180px' } : {}),
				...(bubbleAppearanceState?.chatBoxPosition === 'right' ? { right: '180px' } : {})
			}}
		>
			<Transition
				as={Fragment}
				show={bubbleState?.isOpen || false}
				enter="transition-opacity duration-600"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{(s) => (
					<Card
						ref={chatBoxRef}
						variant="outlined"
						sx={(theme) => ({
							// bgcolor: 'purple',
							zIndex: 9999,
							position: 'absolute',
							bottom: '80px',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'hidden',
							boxSizing: 'border-box',
							boxShadow: 'md',

							transition: `opacity 150ms ease-in-out`,
							width: '500px',

							// opacity: 0,
							...transitionStyles[s],

							...(bubbleAppearanceState?.chatBoxPosition === 'right'
								? { transform: `translateX(${-400 + 50}px)`, left: '-20px' }
								: {}),

							...(bubbleAppearanceState?.chatBoxPosition === 'left'
								? {
										left: '-135px'
									}
								: {}),

							[theme.breakpoints.down('sm')]: {
								width: '100vw',
								maxWidth: '100vw',

								bottom: '-20px',

								...(bubbleAppearanceState?.chatBoxPosition === 'left' ? { left: '-90px' } : {}),
								...(bubbleAppearanceState?.chatBoxPosition === 'right'
									? {
											transform: `translateX(0px)`,
											right: '-20px'
										}
									: {})
							}
						})}
					>
						<Box
							sx={{
								bgcolor: bubbleAppearanceState?.colorPrimary,
								// width: '100%',
								borderBottom: '1px solid',
								borderColor: '#8e8e8e',
								mx: -2,
								mt: -2,
								// mb: -2,
								py: 1
							}}
						>
							<Stack
								direction="row"
								alignItems={'center'}
							>
								<MessagesPaneHeaderStart
									messageListCnt={
										chatLocalState?.messageList ? chatLocalState?.messageList.length : 0
									}
									activeTab={placement}
									appearance={bubbleAppearanceState}
									sender={agent}
									setBubbleState={setBubbleState}
								/>
							</Stack>
						</Box>
						<Tabs
							// variant="plain"

							aria-label="Icon tabs"
							value={placement}
							onChange={(event, newValue) => setPlacement(newValue)}
						>
							<TabPanel
								sx={{ height: '470px' }}
								value="home"
							>
								<PanelStart
									botName={agent?.name}
									description={agent?.description}
									botAvatar={agent?.avatar}
									colorPrimary={bubbleAppearanceState?.colorPrimary}
									bubbleState={bubbleState}
									setBubbleState={setBubbleState}
								/>
							</TabPanel>
							<TabPanel
								sx={{ height: '470px' }}
								value="account"
							>
								<PanelAccount
									userName={agent?.name}
									customActionButton={bubbleAppearanceState?.customActionButton}
									colorPrimary={bubbleAppearanceState?.colorPrimary}
									chatLocalState={chatLocalState}
									setChatLocalState={setChatLocalState}
									handleOtpRequest={handleOtpRequest}
									handleOtpVerify={handleOtpVerify}
									bubbleState={bubbleState}
									setBubbleState={setBubbleState}
								/>
							</TabPanel>
							<TabPanel
								sx={{ height: '470px' }}
								value="messages"
							>
								<PanelMessages
									userName={agent?.name}
									customActionButton={bubbleAppearanceState?.customActionButton}
									colorPrimary={bubbleAppearanceState?.colorPrimary}
									chatLocalState={chatLocalState}
									bubbleState={bubbleState}
									setBubbleState={setBubbleState}
								/>
							</TabPanel>
							<TabList
								tabFlex="auto"
								disableUnderline
								sx={{
									p: 0.5,
									gap: 0.5,

									borderRadius: 'xl',
									bgcolor: 'background.level1',
									[`& .${tabClasses.root}[aria-selected="true"]`]: {
										boxShadow: 'sm',
										bgcolor: 'background.surface'
									}
								}}
							>
								<Tab
									orientation="vertical"
									disableIndicator
									value="home"
								>
									<ListItemDecorator>
										<HomeIcon />
									</ListItemDecorator>
									Home
								</Tab>
								<Tab
									orientation="vertical"
									disableIndicator
									value="account"
								>
									<ListItemDecorator>
										<ManageAccountsIcon />
									</ListItemDecorator>
									Account
								</Tab>
								<Tab
									orientation="vertical"
									disableIndicator
									value="messages"
								>
									<ListItemDecorator>
										<MessageIcon />
									</ListItemDecorator>
									<Stack
										direction="row"
										spacing={0.2}
									>
										<span>Messages</span>
										{chatLocalState?.messageList && chatLocalState?.messageList.length > 0 && (
											<span>({chatLocalState.messageList.length})</span>
										)}
									</Stack>
								</Tab>
							</TabList>
						</Tabs>
						<Divider />
						{bubbleAppearanceState?.showPoweredBy && (
							<Box
								sx={{
									alignItems: 'center',
									justifyContent: 'center',
									display: 'flex',
									width: '100%'
								}}
							>
								Powered by SynthAgents
							</Box>
						)}
					</Card>
				)}
			</Transition>
			<Stack
				sx={{
					overflow: 'visible',
					// position: 'fixed', // Mod
					position: chatBoxCss?.chatButton?.position || 'absolute',
					// position:
					height: '60px',
					// bottom: '80px', // Mod
					// bottom: '5px',
					bottom: chatBoxCss?.chatButton?.bottom || '5px',
					zIndex: 99999,

					...(bubbleAppearanceState?.chatBoxPosition === 'left'
						? {
								// left: '20px',
								// left: '-140px',
								left: chatBoxCss?.chatButton?.left || '20px'
							}
						: {}),
					...(bubbleAppearanceState?.chatBoxPosition === 'right'
						? {
								// right: '20px',
								// right: '-140px',
								right: chatBoxCss?.chatButton?.right || '20px'
							}
						: {})
				}}
			>
				<FloatingButton
					customActionButton={bubbleAppearanceState?.customActionButton}
					hideToolTip={bubbleAppearanceState?.hideToolTip}
					setBubbleState={setBubbleState}
					isOpen={bubbleState?.isOpen}
					colorPrimary={bubbleAppearanceState?.colorPrimary}
					welcomeMessage={bubbleAppearanceState?.welcomeMessage}
					textColor={textColor}
				/>
			</Stack>
		</Box>
	);
};

export default ChatBoxAccount;
