import { useRef } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import { IconButton, Stack } from '@mui/joy';

import { darken } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

const MessageInput = (props) => {
	const { appearance, textAreaValue, setTextAreaValue, onSubmit } = props;

	const textAreaRef = useRef(null);

	const handleClick = () => {
		if (textAreaValue.trim() !== '') {
			onSubmit();
			setTextAreaValue('');
		}
	};

	return (
		<Box sx={{ px: 2 }}>
			<FormControl>
				<Textarea
					// sx={{ bgcolor: 'orange' }}
					placeholder={appearance?.placeHolderText ? appearance?.placeHolderText : 'Type something here…'}
					aria-label="Message"
					ref={textAreaRef}
					onChange={(e) => {
						setTextAreaValue(e.target.value);
					}}
					value={textAreaValue}
					minRows={1}
					maxRows={10}
					endDecorator={
						<Stack
							// sx={{ bgcolor: 'black' }}
							direction="row"
							spacing={1}
							justifyContent="flex-end"
							flexGrow={1}
							minHeight={10}
						>
							<IconButton
								variant="plain"
								color="neutral"
							>
								<FontAwesomeIcon
									style={{
										'--fa-secondary-opacity': '2.0',
										'--fa-primary-color': '#182d73',
										'--fa-secondary-color': 'limegreen'
									}}
									size="lg"
									icon={faSmile}
								/>
							</IconButton>
							<Button
								onClick={handleClick}
								sx={{
									bgcolor: appearance?.colorButton || '#ffffff',
									':hover': {
										bgcolor: darken(appearance?.colorButton || '#ffffff', 0.2),
										color: pickColorBasedOnBgColor(
											appearance?.colorButton || '#ffffff',
											'#ffffff',
											'#000000'
										)
									},
									color: pickColorBasedOnBgColor(
										appearance?.colorButton || '#ffffff',
										'#ffffff',
										'#000000'
									)
								}}
							>
								<FontAwesomeIcon
									style={{
										'--fa-secondary-opacity': '2.0',
										'--fa-primary-color': '#ffffff',
										'--fa-secondary-color': 'limegreen'
									}}
									size="lg"
									icon={faPaperPlane}
								/>
								{/* <PaperPlainIcon className="icon-size-20 m-2 text-gray-600 fill-white" /> */}
							</Button>
						</Stack>
					}
					onKeyDown={(event) => {
						if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
							handleClick();
						}
					}}
				/>
			</FormControl>
		</Box>
	);
};

export default MessageInput;
