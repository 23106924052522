import { useMemo } from 'react';
import StyledMessageRow from './StyledMessageRow';
import clsx from 'clsx';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

const ChatBubble = (props) => {
	const {
		item,
		colorVisitorChat,
		colorBotChat,
		i,
		chatMessagesLength,
		chatMessageFontSize,
		isFirstMessageOfGroup,
		isLastMessageOfGroup,
		attachment = undefined
	} = props;

	const textColorBot = useMemo(
		() => pickColorBasedOnBgColor(colorBotChat || '#ffffff', '#ffffff', '#000000'),
		[colorBotChat]
	);

	const textColorVisitor = useMemo(
		() => pickColorBasedOnBgColor(colorVisitorChat || '#ffffff', '#ffffff', '#000000'),
		[colorVisitorChat]
	);
	return (
		<StyledMessageRow
			colorPrimary={colorVisitorChat || '#ffffff'}
			colorPrimaryContrastText={textColorVisitor}
			colorSecondary={colorBotChat || '#ffffff'}
			colorSecondaryContrastText={textColorBot}
			key={i}
			className={clsx(
				item?.sender === 'You' ? 'me' : 'contact'
				// { 'first-of-group': isFirstMessageOfGroup(item, i) },
				// { 'last-of-group': isLastMessageOfGroup(item, i) },
				// { 'chat-block-bottom': i + 1 === chatMessagesLength },
			)}
		>
			<Stack
				className="bubble"
				sx={{
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					maxWidth: '100%',
					padding: '1.2rem'
				}}
			>
				<Stack
					sx={{
						fontSize: chatMessageFontSize || '1.1rem',
						lineHeight: '1.25',
						whiteSpace: 'pre-wrap'
					}}
				>
					{item?.content}
				</Stack>
				<Typography
					sx={{
						position: 'absolute',
						display: 'none',
						fontSize: '1.1rem',
						width: '100%',
						marginTop: '0.8rem',
						marginBottom: '-2.4rem',
						bottom: '0',
						whiteSpace: 'nowrap'
					}}
					level="body-sm"
					className="time"
				>
					{/* sometimes the timestamp comes back as 1723428211.734 */}
					{item?.timestamp &&
						formatDistanceToNow(
							// new Date(parseInt('000')),
							new Date(parseInt(`${item?.timestamp.toString().split('.')[0]}000`)),
							{
								addSuffix: true
							}
						)}
				</Typography>
			</Stack>
		</StyledMessageRow>
	);
};

export default ChatBubble;
