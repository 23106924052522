import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';

import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import Typography from '@mui/joy/Typography';
import { Controller, useForm } from 'react-hook-form';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import FormHelperText from '@mui/joy/FormHelperText';
import Button from '@mui/joy/Button';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';

import _ from '../../../../lib/@lodash';
import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

import MessagesPaneHeaderStart from './MessagesPaneHeaderStart';
import FloatingButton from './FloatingButton';
import FeedBackIcon from './FeedBackIcon';

const ChatBoxFeedback = (props) => {
	const { chatBoxCss, handleFeedback, bubbleAppearanceState, bubbleState, setBubbleState } = props;

	const chatBoxRef = useRef(null);
	const chatRef = useRef(null);
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

	const transitionStyles = {
		entering: { opacity: 0 },
		entered: { opacity: 1 },
		exiting: { opacity: 0 },
		exited: { opacity: 0 }
	};

	const textColor = useMemo(
		() => pickColorBasedOnBgColor(bubbleAppearanceState?.colorPrimary || '#ffffff', '#ffffff', '#000000'),
		[bubbleAppearanceState?.colorPrimary]
	);

	function scrollToBottom() {
		if (!chatRef.current) {
			return;
		}

		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: 'smooth'
		});
	}

	const [placement, setPlacement] = useState('home');

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		defaultValues: { ratings: '0', reason: 'no feedback' }
	});

	const onSubmit = (data) => {
		handleFeedback(data.ratings, data.reason);
		console.log('data', data);
		setFeedbackSubmitted(true);
	};

	useEffect(
		() => () => {
			if (!feedbackSubmitted) {
				handleFeedback('0', 'no feedback');
			}
		},
		[]
	);

	return (
		<Box
			sx={{
				overflow: 'visible',
				// position: 'fixed', Mod
				// position: 'absolute',
				position: chatBoxCss?.containerBox?.position || 'absolute',
				height: '560px',
				// bottom: '70px', Mod
				// bottom: '20px',
				bottom: chatBoxCss?.containerBox?.bottom || '20px',
				zIndex: 999999,

				...(bubbleAppearanceState?.chatBoxPosition === 'left'
					? {
							left: '180px'
						}
					: {}),
				...(bubbleAppearanceState?.chatBoxPosition === 'right'
					? {
							right: '180px'
						}
					: {})
			}}
		>
			<Transition
				as={Fragment}
				show={bubbleState?.isOpen || false}
				enter="transition-opacity duration-600"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{(s) => (
					<Card
						ref={chatBoxRef}
						variant="outlined"
						sx={(theme) => ({
							// bgcolor: 'purple',
							zIndex: 9999,
							position: 'absolute',
							bottom: '80px',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'hidden',
							boxSizing: 'border-box',
							boxShadow: 'md',

							transition: `opacity 150ms ease-in-out`,
							width: '500px',

							// opacity: 0,
							...transitionStyles[s],

							...(bubbleAppearanceState?.chatBoxPosition === 'right'
								? {
										transform: `translateX(${-400 + 50}px)`,
										left: '-20px'
									}
								: {}),

							...(bubbleAppearanceState?.chatBoxPosition === 'left'
								? {
										left: '-135px'
									}
								: {}),

							[theme.breakpoints.down('sm')]: {
								width: '100vw',
								maxWidth: '100vw',

								bottom: '-20px',

								...(bubbleAppearanceState?.chatBoxPosition === 'left'
									? {
											left: '-90px'
										}
									: {}),
								...(bubbleAppearanceState?.chatBoxPosition === 'right'
									? {
											transform: `translateX(0px)`,
											right: '-20px'
										}
									: {})
							}
						})}
					>
						<Box
							sx={{
								bgcolor: bubbleAppearanceState?.colorPrimary,
								// width: '100%',
								borderBottom: '1px solid',
								borderColor: '#8e8e8e',
								mx: -2,
								mt: -2,
								// mb: -2,
								py: 1
							}}
						>
							<Stack
								direction="row"
								alignItems={'center'}
							>
								<MessagesPaneHeaderStart
									// messageListCnt={chatLocalState?.messageList.length}
									activeTab={'feedback'}
									appearance={bubbleAppearanceState}
									// sender={agent}
									setBubbleState={setBubbleState}
								/>
							</Stack>
						</Box>
						{feedbackSubmitted ? (
							<Stack
								sx={{
									alignItems: 'center',
									justifyContent: 'center',
									display: 'flex',
									height: '480px'
								}}
							>
								Thank you
								<Button onClick={() => setFeedbackSubmitted(false)}>Finished</Button>
							</Stack>
						) : (
							<Stack
								// className="bg-green-200"
								sx={{
									position: 'relative',
									alignItems: 'center',
									// justifyContent: 'center',
									display: 'flex'
									// height: '480px',
								}}
							>
								<Stack
									sx={{
										boxSizing: 'border-box',
										borderRadius: '6px',
										boxShadow: 'md',
										paddingX: '16px',
										paddingY: '24px',
										justifyContent: 'center',
										display: 'flex',
										width: '100%'
									}}
								>
									<Typography level="title-lg">Summary talk with agent</Typography>
									<Stack
										direction="row"
										// justifyContent="center"
										alignItems="center"
										spacing={2}
									>
										<Box
											sx={{
												width: '50%'
											}}
											alignItems="center"
											direction="col"
										>
											<Box>Chat with Agent</Box>
											<Box>Agent name</Box>
										</Box>
										<Box
											sx={{
												width: '50%'
											}}
											alignItems="center"
											direction="col"
										>
											<Box>Chat Durations</Box>
											<Box>Chat time.</Box>
										</Box>
									</Stack>
								</Stack>
								<Stack sx={{ marginTop: '24px' }}>
									<Typography level="title-lg">Thanks for the chat! How do you feel?</Typography>
								</Stack>
								<Stack
									sx={{
										paddingTop: '24px'
									}}
								>
									<form
										className=""
										onSubmit={(ev) => handleSubmit(onSubmit)}
									>
										<Stack spacing={1}>
											<Box>
												<Controller
													name="ratings"
													control={control}
													render={({ field }) => (
														<RadioGroup
															{...field}
															aria-label="platform"
															defaultValue="Website"
															overlay
															name="platform"
															sx={{
																flexDirection: 'row',
																gap: 2,
																[`& .${radioClasses.checked}`]: {
																	[`& .${radioClasses.action}`]: {
																		inset: -1,
																		border: '3px solid',
																		borderColor: 'primary.500'
																	}
																},
																[`& .${radioClasses.radio}`]: {
																	display: 'contents',
																	'& > svg': {
																		zIndex: 2,
																		position: 'absolute',
																		top: '-8px',
																		right: '-8px',
																		bgcolor: 'background.surface',
																		borderRadius: '50%'
																	}
																}
															}}
														>
															{[
																{ label: 'poor', value: 1 },
																{ label: 'below avg', value: 2 },
																{ label: 'okay', value: 3 },
																{ label: 'good', value: 4 },
																{ label: 'Excellent', value: 5 }
															].map((value) => (
																<Sheet
																	key={value.label}
																	variant="outlined"
																	sx={{
																		borderRadius: 'md',
																		boxShadow: 'sm',
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																		gap: 1.5,
																		p: 2,
																		minWidth: 80,
																		':hover': {
																			backgroundColor: 'rgba(221,217,216,0.49)'
																		}
																	}}
																>
																	<Radio
																		id={value.label}
																		value={value.value}
																		checkedIcon={
																			<FontAwesomeIcon
																				style={{
																					// '--fa-secondary-opacity': '2.0',
																					'--fa-primary-color': '#FDD835',
																					'--fa-secondary-color': '#bc0909'
																				}}
																				icon={faCircleCheck}
																				size={'2xl'}
																				// rotation={90}
																				// flip
																			/>
																		}
																	/>
																	<FeedBackIcon
																		iconName={value.label}
																		variant="soft"
																		size="sm"
																	/>
																	<FormLabel htmlFor={value.label}>
																		{_.startCase(value.label)}
																	</FormLabel>
																</Sheet>
															))}
														</RadioGroup>
													)}
												/>
											</Box>
											<Box
												sx={{
													paddingTop: '24px',
													paddingBottom: '92px'
												}}
											>
												<Typography level="title-lg">
													What are the main reason for your rating?
												</Typography>
												<Controller
													name="reason"
													control={control}
													render={({ field }) => (
														<Textarea
															{...field}
															placeholder="Fill in the reason for your rating"
															minRows={4}
														/>
													)}
												/>
												<FormHelperText>This is a helper text.</FormHelperText>
											</Box>
											<Box
												// className="absolute inset-x-0 bottom-0 bg-green-200"
												sx={{
													position: 'absolute',

													bottom: 0,
													right: 0,
													left: 0,
													paddingX: '18px',
													justifyContent: 'space-between',
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center'
												}}
											>
												<Button variant="outlined">Cancel</Button>
												<Button onClick={handleSubmit(onSubmit)}>Submit</Button>
											</Box>
										</Stack>
									</form>
								</Stack>
							</Stack>
						)}

						<Divider />
						{bubbleAppearanceState?.showPoweredBy && (
							<Box
								sx={{
									alignItems: 'center',
									justifyContent: 'center',
									display: 'flex',
									width: '100%'
								}}
							>
								Powered by SynthAgents
							</Box>
						)}
					</Card>
				)}
			</Transition>
			<Stack
				sx={{
					overflow: 'visible',
					// position: 'fixed', // Mod
					position: chatBoxCss?.chatButton?.position || 'absolute',
					// position:
					height: '60px',
					// bottom: '80px', // Mod
					// bottom: '5px',
					bottom: chatBoxCss?.chatButton?.bottom || '5px',
					zIndex: 99999,

					...(bubbleAppearanceState?.chatBoxPosition === 'left'
						? {
								// left: '20px',
								// left: '-140px',
								left: chatBoxCss?.chatButton?.left || '20px'
							}
						: {}),
					...(bubbleAppearanceState?.chatBoxPosition === 'right'
						? {
								// right: '20px',
								// right: '-140px',
								right: chatBoxCss?.chatButton?.right || '20px'
							}
						: {})
				}}
			>
				<FloatingButton
					customActionButton={bubbleAppearanceState?.customActionButton}
					hideToolTip={bubbleAppearanceState?.hideToolTip}
					setBubbleState={setBubbleState}
					isOpen={bubbleState?.isOpen}
					colorPrimary={bubbleAppearanceState?.colorPrimary}
					welcomeMessage={bubbleAppearanceState?.welcomeMessage}
					textColor={textColor}
				/>
			</Stack>
		</Box>
	);
};

export default ChatBoxFeedback;
