import { useForm, Controller } from 'react-hook-form';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Avatar from '@mui/joy/Avatar';
import SendIcon from '@mui/icons-material/Send';
// import { useChatBubbleApp } from '../context/useChatBubble';
// import _ from 'src/lib/@lodash';

const SubPanelAccountVerifyEmail = (props) => {
	const { bubbleState, setBubbleState, handleOtpRequest } = props;

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		defaultValues: {}
	});

	const onSubmit = (data) => {
		console.log('data', data);
		handleOtpRequest(data.name, data.email);
		// handleOtpRequest('Lecole Cole', 'lecole@gmail.com');
	};

	return (
		<Stack
			className=""
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Typography
					className="text-gray-800"
					level="h3"
					noWrap
				>
					Verify your email
				</Typography>
				<Typography
					className="text-gray-800"
					level="body-lg"
					noWrap
				>
					Verify your email to view chat history
				</Typography>
			</Stack>
			<Stack>
				<form
					className="w-full"
					onSubmit={(ev) => handleSubmit(onSubmit)}
				>
					<Stack spacing={1}>
						<Stack>
							<FormLabel>Name</FormLabel>
							<Controller
								name="name"
								control={control}
								render={({field}) => (
									<Input
										{...field}
										required
									/>
								)}
							/>
							<FormHelperText>This is a helper text.</FormHelperText>
						</Stack>
						<Stack>
							<FormLabel>Email</FormLabel>
							<Controller
								name="email"
								control={control}
								render={({field}) => (
									<Input
										{...field}
										required
									/>
								)}
							/>
							<FormHelperText>This is a helper text.</FormHelperText>
						</Stack>
						{/* <Button type="submit">Submit</Button> */}
						<Button
							// sx={{ marginX: '16px'}}
							className="mx-8"
							variant="outlined"
							// color="primary"
							// disabled={_.isEmpty(dirtyFields) || !isValid}
							onClick={handleSubmit(onSubmit)}
						>
							Submit
						</Button>
					</Stack>
				</form>
			</Stack>
			<div className='absolute bottom-0 right-0 flex w-full  justify-center'>
				<Button
					variant="solid"
					onClick={() =>
						setBubbleState((prevState) => ({
							...prevState,
							chatBotPanelActive: 'active'
						}))
					}
					endDecorator={<SendIcon/>}
				>
					{bubbleState?.botOnline ? 'Continue' : 'Start'} Conversation
				</Button>
				</div>
		</Stack>
);
};

const SubPanelAccountVerifyOTP = (props) => {
	const {handleOtpVerify } = props;

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		defaultValues: {}
	});

	const onSubmit = (data) => {
		console.log('data', data);
		handleOtpVerify('lecole@gmail.com', data.otp);
	};
	return (
		<Stack
			className=""
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Typography
					className="text-gray-800"
					level="h3"
					noWrap
				>
					Verify OTP
				</Typography>
				<Typography
					className="text-gray-800"
					level="body-lg"
					noWrap
				>
					We sent the OTP to your email address jason.born@gmail.com
				</Typography>
			</Stack>
			<Stack>
				<form
					className="w-full"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Stack spacing={1}>
						<Stack>
							<FormLabel>OTP</FormLabel>
							<Controller
								name="otp"
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										placeholder="1234"
										required
									/>
								)}
							/>
							<FormHelperText>This is a helper text.</FormHelperText>
						</Stack>
						<Button variant="solid" type="submit">Submit</Button>
					</Stack>
				</form>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<Typography
						className="text-gray-800"
						level="body-lg"
						noWrap
					>
						Didn't receive the OTP yet? Resend
					</Typography>
					<Typography
						className="text-gray-800"
						level="body-lg"
						noWrap
					>
						Edit details
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

const SubPanelAccountDetail = (props) => {
	const { name, email, setChatLocalState, customActionButton, primaryColor } = props;
	return (
		<Stack
			sx={{
				display: 'flex',
				width: '100%',
				maxHeight: '480px',
				minHeight: '380px'
			}}
			className="h-full"
			direction="column"
			justifyContent="start"
		>
			<Stack>
				<Stack
					direction="row"
					justifyContent="end"
				>
					<Button
						variant="solid"
						onClick={() =>
							setChatLocalState((prevState) => ({
								...prevState,
								messageList: [],
								contactDetails: {},
								twoFa: { send: false, verified: false, otpSet: false }
							}))
						}
					>
						Logout
					</Button>
				</Stack>
				<Avatar
					sx={{ backgroundColor: primaryColor }}
					className="h-60 w-60"
					size="lg"
					src={customActionButton}
				/>
				<Typography
					className="text-gray-800"
					level="h2"
					noWrap
				>
					Name
				</Typography>
				<Typography
					className="text-gray-600"
					level="title-md"
					noWrap
				>
					{name}
				</Typography>
			</Stack>
			<Stack>
				<Typography
					className="text-gray-800"
					level="h2"
					noWrap
				>
					Email
				</Typography>
				<Typography
					className="text-gray-600"
					level="title-md"
					noWrap
				>
					{email}
				</Typography>
			</Stack>
		</Stack>
	);
};

const PanelAccount = (props) => {
	const {
		customActionButton,
		primaryColor,
		chatLocalState,
		setChatLocalState,
		handleOtpRequest,
		handleOtpVerify,
		bubbleState,
		setBubbleState
	} = props;

	return (
		<Box
			className="relative"
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={(theme) => ({
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				position: 'relative',
				maxHeight: '480px',
				minHeight: '380px',
				overflow: 'auto',

				[theme.breakpoints.down('sm')]: {
					height: '80vh',
					maxHeight: '80vh',
					maxWidth: '100vw'
				}
			})}
		>
			{/* {chatLocalState?.otpSet ? ( */}
			{/*  <SubPanelAccountVerifyOTP handleOtpVerify={handleOtpVerify} /> */}
			{/* ) : ( */}
			{/*  <SubPanelAccountVerifyEmail */}
			{/*    handleOtpRequest={handleOtpRequest} */}
			{/*    setBubbleState={setBubbleState} */}
			{/*  /> */}
			{/* )} */}
			{/* <Button */}
			{/*  onClick={() => setChatLocalState({ otpSet: !chatLocalState?.otpSet })} */}
			{/* > */}
			{/*  switch */}
			{/* </Button> */}

			{chatLocalState?.contactDetails?.email && chatLocalState?.twoFa?.verified ? (
				<SubPanelAccountDetail
					name={chatLocalState?.contactDetails?.name}
					email={chatLocalState?.contactDetails?.email}
					setChatLocalState={setChatLocalState}
					customActionButton={customActionButton}
					primaryColor={primaryColor}
					bubbleState={bubbleState}
					setBubbleState={setBubbleState}
				/>
			) : (
				<>
					{chatLocalState?.twoFa?.send && !chatLocalState?.twoFa?.verified && (
						<SubPanelAccountVerifyOTP
							handleOtpVerify={handleOtpVerify}
							bubbleState={bubbleState}
						/>
					)}
					{!chatLocalState?.twoFa?.send && chatLocalState?.twoFa?.verified && (
						<SubPanelAccountVerifyOTP
							handleOtpVerify={handleOtpVerify}
							bubbleState={bubbleState}
						/>
					)}
					{!chatLocalState?.twoFa?.send && !chatLocalState?.twoFa?.verified && (
						<SubPanelAccountVerifyEmail
							handleOtpRequest={handleOtpRequest}
							bubbleState={bubbleState}
							setBubbleState={setBubbleState}
						/>
					)}
				</>
			)}
		</Box>
	);
};

export default PanelAccount;
