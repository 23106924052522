import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

import { Transition } from '@headlessui/react';

import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/joy/Divider';

import AnimatedEllipsis from '../../core/animated/AnimatedEllipsis';

import ChatBubble from './ChatBubble';
import FloatingButton from './FloatingButton';
import MessageInput from './MessageInput';
import MessageTemplates from './MessageTemplates';
import MessagesPaneHeader from './MessagesPaneHeader';
import AvatarWithStatus from './AvatarWithStatus';

const ChatBoxActive = (props) => {
	const {
		agent,
		chatBoxCss,
		bubbleState,
		bubbleAppearanceState,
		setBubbleState,
		chatMessages,
		handleSendMessage,
		handelJoinRoom
	} = props;

	const chatBoxRef = useRef(null);
	const chatRef = useRef(null);
	const [textAreaValue, setTextAreaValue] = useState('');

	const transitionStyles = {
		entering: { opacity: 0 },
		entered: { opacity: 1 },
		exiting: { opacity: 0 },
		exited: { opacity: 0 }
	};

	function scrollToBottom() {
		if (!chatRef.current) {
			return;
		}

		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: 'smooth'
		});
	}

	const textColor = useMemo(
		() => pickColorBasedOnBgColor(bubbleAppearanceState?.colorPrimary || '#ffffff', '#ffffff', '#000000'),
		[bubbleAppearanceState?.colorPrimary]
	);

	const isFirstMessageOfGroup = (item, i) =>
		i === 0 || (chatMessages[i - 1] && chatMessages[i - 1].sender !== item.sender);

	const isLastMessageOfGroup = (item, i) =>
		i === chatMessages.length - 1 || (chatMessages[i + 1] && chatMessages[i + 1].sender !== item.sender);

	useEffect(() => {
		if (chatMessages) {
			setTimeout(scrollToBottom);
		}
	}, [chatMessages]);

	useEffect(() => {
		// We only want to join the room, if we are not currently in a room.
		if (!bubbleState.roomJoined) {
			handelJoinRoom();
		}
	}, []);

	return (
		<Box
			sx={{
				overflow: 'visible',
				// position: 'fixed', Mod
				// position: 'absolute',
				position: chatBoxCss?.containerBox?.position || 'absolute',
				height: '360px',
				// bottom: '70px', Mod
				// bottom: '20px',
				bottom: chatBoxCss?.containerBox?.bottom || '20px',
				zIndex: 999999,

				...(bubbleAppearanceState?.chatBoxPosition === 'left'
					? {
							left: '180px'
						}
					: {}),
				...(bubbleAppearanceState?.chatBoxPosition === 'right'
					? {
							right: '180px'
						}
					: {})
			}}
		>
			<Transition
				as={Fragment}
				show={bubbleState?.isOpen || false}
				enter="transition-opacity duration-600"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{(s) => (
					<Card
						ref={chatBoxRef}
						variant="outlined"
						sx={(theme) => ({
							zIndex: 9999,
							position: 'absolute',
							bottom: '80px',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'hidden',
							boxSizing: 'border-box',
							boxShadow: 'md',

							transition: `opacity 150ms ease-in-out`,
							width: '500px',

							// opacity: 0,
							...transitionStyles[s],

							...(bubbleAppearanceState?.chatBoxPosition === 'right'
								? {
										transform: `translateX(${-400 + 50}px)`,
										left: '-20px'
									}
								: {}),

							...(bubbleAppearanceState?.chatBoxPosition === 'left'
								? {
										left: '-135px'
									}
								: {}),

							[theme.breakpoints.down('sm')]: {
								width: '100vw',
								maxWidth: '100vw',

								bottom: '-20px',

								...(bubbleAppearanceState?.chatBoxPosition === 'left'
									? {
											left: '-90px'
										}
									: {}),
								...(bubbleAppearanceState?.chatBoxPosition === 'right'
									? {
											transform: `translateX(0px)`,
											right: '-20px'
										}
									: {})
							}
						})}
					>
						<Box
							sx={{
								bgcolor: bubbleAppearanceState?.colorPrimary,
								// width: '100%',
								borderBottom: '1px solid',
								borderColor: '#8e8e8e',
								mx: -2,
								mt: -2,
								// mb: -2,
								py: 1
							}}
						>
							<Stack
								direction="row"
								alignItems={'center'}
							>
								<MessagesPaneHeader
									appearance={bubbleAppearanceState}
									botOnline={bubbleState?.botOnline}
									botStatus={bubbleState?.botStatus}
									agent={agent}
									setState={setBubbleState}
								/>
							</Stack>
						</Box>
						<Box
							// className="bg-green-200"
							ref={chatRef}
							sx={(theme) => ({
								// flex: 1,
								// display: 'flex',
								width: '100%',
								position: 'relative',

								// height: 'calc(100vh - 200px)',

								maxHeight: '420px',
								minHeight: '380px',
								overflow: 'auto',

								[theme.breakpoints.down('sm')]: {
									height: '80vh',
									maxHeight: '80vh',
									maxWidth: '100vw'
								},

								'& .message-agent': {
									backgroundColor: bubbleAppearanceState?.colorBotChat,
									// borderColor: bubbleAppearanceState?.colorPrimary,
									color: pickColorBasedOnBgColor(
										bubbleAppearanceState?.colorPrimary || '#ffffff',
										'#ffffff',
										'#000000'
									)
								}
							})}
						>
							<Stack justifyContent="flex-end">
								{chatMessages.map((item, i) => {
									const isYou = item.sender === 'You';
									return (
										<Stack
											key={i}
											direction="row"
											spacing={2}
											flexDirection={isYou ? 'row-reverse' : 'row'}
										>
											{!bubbleAppearanceState?.hideBotAvatar && item.sender !== 'You' && (
												<AvatarWithStatus
													online={item.sender.online}
													src={bubbleAppearanceState.avatar}
												/>
											)}
											<ChatBubble
												item={item}
												colorPrimary={bubbleAppearanceState?.colorPrimary || '#ffffff'}
												colorBotChat={bubbleAppearanceState?.colorBotChat || '#ffffff'}
												colorVisitorChat={bubbleAppearanceState?.colorVisitorChat || '#ffffff'}
												i={i}
												chatMessagesLength={chatMessages.length}
												chatMessageFontSize={bubbleAppearanceState?.fontSize}
												isFirstMessageOfGroup={isFirstMessageOfGroup}
												isLastMessageOfGroup={isLastMessageOfGroup}
											/>
										</Stack>
									);
								})}
							</Stack>
							{bubbleState?.showBotThinking && bubbleState?.botOnline && (
								<Stack>
									<ChatBubble
										item={{
											sender: {
												name: 'Melissa',
												username: '@melissa',
												avatar: bubbleAppearanceState.avatar,
												online: true
											},
											content: (
												<AnimatedEllipsis
													fontSize={'24px'}
													marginLeft="28px"
													spacing="5px"
												/>
											),
											timestamp: 1723235954
										}}
										colorPrimary={bubbleAppearanceState?.colorPrimary || '#ffffff'}
										colorBotChat={'#e0e0e0'}
										colorVisitorChat={bubbleAppearanceState?.colorVisitorChat || '#ffffff'}
										i={'i'}
										chatMessagesLength={chatMessages.length}
										chatMessageFontSize={chatBoxCss?.chatMessage}
										isFirstMessageOfGroup={isFirstMessageOfGroup}
										isLastMessageOfGroup={isLastMessageOfGroup}
									/>
								</Stack>
							)}
						</Box>
						{agent?.quickPrompts && (
							<Box
								sx={{
									width: '100%',
									position: 'relative',
									maxHeight: '80px',
									minHeight: '40px',
									overflow: 'auto'
								}}
							>
								<Stack
									spacing={2}
									justifyContent="flex-end"
								>
									<MessageTemplates
										quickPrompts={agent.quickPrompts}
										handleSendMessage={handleSendMessage}
									/>
								</Stack>
							</Box>
						)}

						<Box
							sx={{
								width: '100%',
								// mt: 1,
								py: 1
								// bgcolor: 'green',
							}}
						>
							<MessageInput
								appearance={bubbleAppearanceState}
								textAreaValue={textAreaValue}
								setTextAreaValue={setTextAreaValue}
								onSubmit={() => {
									handleSendMessage(textAreaValue, 'guestChatWithGptAgent');
								}}
							/>
						</Box>
						<Divider />
						{bubbleAppearanceState?.showPoweredBy && (
							<Box
								sx={{
									alignItems: 'center',
									justifyContent: 'center',
									display: 'flex',
									width: '100%'
								}}
							>
								Powered by SynthAgents
							</Box>
						)}
					</Card>
				)}
			</Transition>
			<Stack
				sx={{
					overflow: 'visible',
					// position: 'fixed', // Mod
					position: chatBoxCss?.chatButton?.position || 'absolute',
					// position:
					height: '60px',
					// bottom: '80px', // Mod
					// bottom: '5px',
					bottom: chatBoxCss?.chatButton?.bottom || '5px',
					zIndex: 99999,

					...(bubbleAppearanceState?.chatBoxPosition === 'left'
						? {
								// left: '20px',
								// left: '-140px',
								left: chatBoxCss?.chatButton?.left || '20px'
							}
						: {}),
					...(bubbleAppearanceState?.chatBoxPosition === 'right'
						? {
								// right: '20px',
								// right: '-140px',
								right: chatBoxCss?.chatButton?.right || '20px'
							}
						: {})
				}}
			>
				<FloatingButton
					customActionButton={bubbleAppearanceState?.customActionButton}
					hideToolTip={bubbleAppearanceState?.hideToolTip}
					setBubbleState={setBubbleState}
					isOpen={bubbleState?.isOpen}
					colorPrimary={bubbleAppearanceState?.colorPrimary}
					welcomeMessage={bubbleAppearanceState?.welcomeMessage}
					textColor={textColor}
				/>
			</Stack>
		</Box>
	);
};

export default ChatBoxActive;
