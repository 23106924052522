import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { lighten } from '@mui/material/styles';

import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';

const MessagesPaneHeaderStart = (props) => {
	const { activeTab, appearance, setBubbleState, messageListCnt } = props;

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				width: '100%',
				borderColor: 'divider'
			}}
			py={{ xs: 2, md: 2 }}
			px={{ xs: 1, md: 2 }}
		>
			<Stack
				direction="row"
				spacing={{ xs: 1, md: 2 }}
				alignItems="center"
			>
				<IconButton
					variant="plain"
					color="neutral"
					size="lg"
					sx={{
						display: { xs: 'inline-flex', sm: 'none' },
						':hover': {
							bgcolor: lighten(appearance.colorButton, 0.2),
							color: pickColorBasedOnBgColor(appearance?.colorButton || '#000000', '#000000', '#ffffff')
						}
					}}
					// onClick={() => toggleMessagesPane()}
				>
					<CloseRoundedIcon />
				</IconButton>
			</Stack>
			<Stack
				direction="row"
				spacing={{ xs: 1, md: 2 }}
				alignItems="center"
			>
				{(() => {
					switch (activeTab) {
						case 'home':
							return (
								<Typography
									fontWeight="lg"
									fontSize="lg"
									noWrap
									sx={{
										color: pickColorBasedOnBgColor(
											appearance?.colorPrimary || '#ffffff',
											'#ffffff',
											'#000000'
										)
									}}
								>
									Home
								</Typography>
							);
						case 'account':
							return (
								<Typography
									fontWeight="lg"
									fontSize="lg"
									noWrap
									sx={{
										color: pickColorBasedOnBgColor(
											appearance?.colorPrimary || '#ffffff',
											'#ffffff',
											'#000000'
										)
									}}
								>
									Account
								</Typography>
							);
						case 'messages':
							return (
								<Typography
									fontWeight="lg"
									fontSize="lg"
									noWrap
									sx={{
										color: pickColorBasedOnBgColor(
											appearance?.colorPrimary || '#ffffff',
											'#ffffff',
											'#000000'
										)
									}}
								>
									Messages {messageListCnt > 0 && <span>({messageListCnt})</span>}
								</Typography>
							);
						case 'feedback':
							return (
								<Typography
									fontWeight="lg"
									fontSize="lg"
									noWrap
									sx={{
										color: pickColorBasedOnBgColor(
											appearance?.colorPrimary || '#ffffff',
											'#ffffff',
											'#000000'
										)
									}}
								>
									Feedback
								</Typography>
							);
						default:
							return (
								<Typography
									fontWeight="lg"
									fontSize="lg"
									noWrap
									sx={{
										color: pickColorBasedOnBgColor(
											appearance?.colorPrimary || '#ffffff',
											'#ffffff',
											'#000000'
										)
									}}
								>
									default
								</Typography>
							);
					}
				})()}
			</Stack>
			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
			>
				<IconButton
					variant="outlined"
					// sx={{ ml: 'auto' }}
					sx={{
						color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
					}}
					size="sm"
					onClick={() =>
						setBubbleState((prevState) => ({
							...prevState,
							isOpen: false
						}))
					}
				>
					<CloseRoundedIcon />
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default MessagesPaneHeaderStart;
