import ChatBoxAccount from './ChatBoxAccount';
import ChatBoxActive from './ChatBoxActive';
import ChatBoxFeedback from './ChatBoxFeedback';

const ChatBoxTemplate = (props) => {
	const {
		agent,
		chatLocalState,
		chatBoxCss,
		bubbleState,
		setBubbleState,
		chatMessages,
		bubbleAppearanceState,
		setBubbleAppearanceState,
		handleSendMessage,
		handleOtpRequest,
		handleOtpVerify,
		handleFeedback,
		handelJoinRoom
	} = props;

	// console.log('ChatBoxTemplate agent', agent);

	return (
		<div>
			{bubbleState?.hasAgenDetailsLoaded && (
				<>
					{(() => {
						switch (bubbleState?.chatBotPanelActive) {
							case 'account':
								return (
									<ChatBoxAccount
										agent={agent}
										chatBoxCss={chatBoxCss}
										chatMessages={chatMessages}
										chatLocalState={chatLocalState}
										bubbleAppearanceState={bubbleAppearanceState}
										setBubbleAppearanceState={setBubbleAppearanceState}
										bubbleState={bubbleState}
										setBubbleState={setBubbleState}
										handleOtpRequest={handleOtpRequest}
										handleOtpVerify={handleOtpVerify}
									/>
								);
							case 'active':
								return (
									<ChatBoxActive
										agent={agent}
										chatBoxCss={chatBoxCss}
										chatMessages={chatMessages}
										bubbleState={bubbleState}
										bubbleAppearanceState={bubbleAppearanceState}
										setBubbleAppearanceState={setBubbleAppearanceState}
										setBubbleState={setBubbleState}
										handleSendMessage={handleSendMessage}
										handelJoinRoom={handelJoinRoom}
									/>
								);

							case 'feedback':
								return (
									<ChatBoxFeedback
										agent={agent}
										chatBoxCss={chatBoxCss}
										handleFeedback={handleFeedback}
										bubbleAppearanceState={bubbleAppearanceState}
										setBubbleAppearanceState={setBubbleAppearanceState}
										chatLocalState={chatLocalState}
										bubbleState={bubbleState}
										setBubbleState={setBubbleState}
									/>
								);
							default:
								return (
									<span className="flex-shrink-0 inline-block px-8 py-2 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
										No panel
									</span>
								);
						}
					})()}
				</>
			)}
		</div>
	);
};

export default ChatBoxTemplate;
