import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import SendIcon from '@mui/icons-material/Send';

const PanelStart = (props) => {
	const { botName, description, botAvatar, primaryColor, bubbleState, setBubbleState } = props;

	return (
		<Box
			className="relative"
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={(theme) => ({
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				position: 'relative',
				maxHeight: '480px',
				minHeight: '380px',
				overflow: 'auto',

				[theme.breakpoints.down('sm')]: {
					height: '80vh',
					maxHeight: '80vh',
					maxWidth: '100vw'
				}
			})}
		>
			<Stack
				className=""
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				{botAvatar ? (
					<Avatar
						sx={{ backgroundColor: bubbleState?.primaryColor, height: '10rem', width: '10rem' }}
						// className="h-60 w-60"
						size="lg"
						src={botAvatar}
					/>
				) : (
					<div className="h-40"> Logo </div>
				)}
				<Typography
					className="text-gray-800"
					level="h2"
					noWrap
				>
					{botName}
				</Typography>
				<Typography
					className="text-gray-600"
					level="title-md"
				>
					{description}
				</Typography>
				<div className='absolute bottom-0 right-0 flex w-full  justify-center'>
				<Button
					variant="solid"
					onClick={() =>
						setBubbleState((prevState) => ({
							...prevState,
							chatBotPanelActive: 'active'
						}))
					}
					endDecorator={<SendIcon />}
				>
					{bubbleState?.botOnline ? 'Continue' : 'Start'} Conversation
				</Button>
				</div>

			</Stack>
		</Box>
	);
};

export default PanelStart;
