import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const useAnimatedEllipsis = (duration = 2, perDelay = 0.2) => {
	const styleNodeRef = useRef();

	useEffect(() => {
		if (!styleNodeRef.current) {
			const styleNode = document.createElement('style');
			styleNode.type = 'text/css';
			styleNode.innerHTML = `
        @-webkit-keyframes animated-ellipsis {
          0%   { opacity: 0; }
          50%  { opacity: 0; }
          100% { opacity: 1; }
        }
        @keyframes animated-ellipsis {
          0%   { opacity: 0; }
          50%  { opacity: 0; }
          100% { opacity: 1; }
        }`;
			document.head.appendChild(styleNode);
			styleNodeRef.current = styleNode;
		}
	}, []);

	return { duration, perDelay };
};

const AnimatedText = ({ text, duration = 2, perDelay = 0.2, fontSize, marginLeft }) => {
	const animationProps = useAnimatedEllipsis(duration, perDelay);

	const textSpans = text.split('').map((char, index) => {
		const delay = index * animationProps.perDelay;
		const spanStyle = {
			opacity: 0,
			animation: `animated-ellipsis ${animationProps.duration}s infinite`,
			animationDelay: `${delay}s`,
			WebkitAnimation: `animated-ellipsis ${animationProps.duration}s infinite`, // For Safari
			WebkitAnimationDelay: `${delay}s`, // For Safari
			display: 'inline-block' // Ensures that spacing and animations apply correctly
		};

		return (
			<span
				key={index}
				style={spanStyle}
			>
				{char}
			</span>
		);
	});

	const wrapperStyle = {
		fontSize,
		marginLeft
	};

	return <div style={wrapperStyle}>{textSpans}</div>;
};

AnimatedText.prototype = {
	steps: PropTypes.number,
	duration: PropTypes.number,
	perDelay: PropTypes.number,
	fontSize: PropTypes.string,
	marginLeft: PropTypes.string,
	spacing: PropTypes.string,
	text: PropTypes.string
};

export default AnimatedText;
