import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import _ from '../../../../lib/@lodash';

import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';
import AnimatedText from '../../core/animated/AnimatedText';

const statusColor = (status) => {
	if (status === 'loading') {
		return '#FDD835';
	} else {
		return '#bc0909';
	}
};

const MessagesPaneHeader = (props) => {
	const { botOnline, botStatus, agent, appearance, setState } = props;

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				width: '100%',
				// borderBottom: '1px solid',
				borderColor: 'divider'
				// bgcolor: 'red',
			}}
			py={{ xs: 2, md: 2 }}
			px={{ xs: 1, md: 2 }}
		>
			<Stack
				direction="row"
				spacing={{ xs: 1, md: 2 }}
				alignItems="center"
			>
				<Stack
					spacing={1}
					direction="row"
					alignItems="center"
				>
					<IconButton
						// variant="outlined"
						// sx={{ ml: 'auto' }}
						sx={{
							color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
						}}
						size="sm"
						onClick={() =>
							setState((prevState) => ({
								...prevState,
								chatBotPanelActive: 'account'
							}))
						}
					>
						<ArrowBackIcon
							sx={{
								color: pickColorBasedOnBgColor(
									appearance?.colorPrimary || '#ffffff',
									'#ffffff',
									'#000000'
								)
							}}
						/>
					</IconButton>
				</Stack>
				{!appearance?.hideBotAvatar && (
					<Avatar
						className=""
						size="lg"
						src={appearance?.avatar}
					/>
				)}

				<div>
					<Typography
						fontWeight="lg"
						fontSize="lg"
						noWrap
						sx={{
							color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
						}}
						endDecorator={
							botOnline ? (
								<Chip
									variant="outlined"
									size="sm"
									color="neutral"
									sx={{
										'--Chip-radius': '6px',
										color: '#000000'
									}}
									startDecorator={
										<CircleIcon
											sx={{ fontSize: 8 }}
											color={'success'}
										/>
									}
									slotProps={{ root: { component: 'span' } }}
								>
									Online
								</Chip>
							) : (
								<Chip
									variant="outlined"
									size="sm"
									color="neutral"
									sx={{
										'--Chip-radius': '6px',
										color: '#000000'
									}}
									startDecorator={<CircleIcon sx={{ color: statusColor(botStatus), fontSize: 8 }} />}
									slotProps={{ root: { component: 'span' } }}
								>
									{botStatus ? (
										<AnimatedText
											fontSize={'8px'}
											spacing="5px"
											text={_.startCase(botStatus)}
										/>
									) : (
										'Offline'
									)}
								</Chip>
							)
						}
					>
						{agent?.name}
					</Typography>

					<Typography
						sx={{
							color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
						}}
						level="body2"
					>
						{agent?.username}
					</Typography>
				</div>
			</Stack>
			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
			>
				<IconButton
					variant="outlined"
					sx={{
						color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
					}}
					size="sm"
					onClick={() =>
						setState((prevState) => ({
							...prevState,
							isOpen: false
						}))
					}
				>
					<CloseRoundedIcon
						sx={{
							color: pickColorBasedOnBgColor(appearance?.colorPrimary || '#ffffff', '#ffffff', '#000000')
						}}
					/>
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default MessagesPaneHeader;
