import { createContext, useEffect, useLayoutEffect, useState, useContext, useRef, useCallback } from 'react';
import { DateTime } from 'luxon';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import useWebSocket from 'react-use-websocket';

import { useSessionStorage } from 'react-storage-complete';


import generateKey from '../../../../utils/generateKey';
import defaultImage from '../../../assets/images/faces/default-avatar.png';
import _ from '../../../../lib/@lodash';
import { Base64 } from 'js-base64';

const SAActionLogo = 'https://www.synthagents.ai/assets/libs/synthagents_favicon.svg'

const chatBubbleAppContext = createContext({});

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

const defaultChatBubbleAppearance = {
	theme: 'light',
	colorPrimary: '#7b7783',
	colorVisitorChat: '#989889',
	colorButton: '#60706c',
	colorBotChat: '#81878c',

	placeHolderText: 'What can I help you with?',
	initialMessage: 'Hi, how can I help you?',
	chatBoxPosition: 'right',
	fontSize: '12px',
	customActionButton: SAActionLogo,
	showPoweredBy: true,
	hideBotAvatar: null,
	hideLearnMore: null,
	hideHelp: null,
	hideToolTip: null,
	companyLogo:
		'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
	chatBoxCss: {
		chatButton: {
			bottom: '5px',
			left: '-142px',
			position: 'absolute',
			right: '-140px'
		},
		containerBox: {
			bottom: '20px',
			position: 'absolute'
		}
	}
	// chatBoxCss: {
	//   containerBox: {
	//     position: 'absolute', // 'fixed', or 'absolute'
	//     bottom: '20px', // '70px' or '20px'
	//   },
	//   chatButton: {
	//     position: 'absolute', //' absolute' or 'fixed'
	//     bottom: '5px', // '5px' or '80px'
	//     left: '-140px', // '-140px' or  '20px',
	//     right: '-140px', // '-140px' or  '20px',
	//   },
	//   chatMessage: {
	//     fontSize: '12px',
	//   },
	// },
};

const AGENT_INITIAL_STATE = {
	id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
	name: 'Helpdesk Agent',
	// username: '@cameron',
	description: 'An AI chatbot created by SynthAgents to help with customer service.',
	// email: 'cameron@company.com',
	initialMessage: 'Hi, how can I help you?',
	status: 'offline',
	online: false,
	avatar: defaultImage,
	about: "Hi there! I'm using SynthAgentChat.",
	quickPrompts: [
		{ title: "What's the pricing?", prompt: "What's the pricing?" },
		{ title: 'Talk to Support.', prompt: 'Talk to Support.' },
		{ title: 'Talk to Sales.', prompt: 'Talk to Sales.' },
		{ title: 'Talk to Human', prompt: 'Talk to Human.' },
		{ title: '**Stop Bot**', prompt: '@@LC@@' }
	]
};

const BUBBLE_INITIAL_STATE = {
	isOpen: false,
	hasOpenOnce: false,
	showInitialMessage: false,
	showHelp: true,
	showCaptureForm: false,
	showBotThinking: false,
	botOnline: false,
	botStatus: 'loading',
	isCaptureLoading: false,
	chatBotPanelActive: 'account',
	hasAgenDetailsLoaded: false,
	roomJoined: false,
	visitorEmail: ''
};

const chatBubbleInitialState = {
	messageList: [],
	contactDetails: {},
	twoFa: { send: false, verified: false, otpSet: false }
};

// Provider component that wraps your app and makes chat bubble object ...
const ChatBubbleAppProvide = ({ children }) => {
	const chatBubbleValue = useChatBubbleAppProvide();

	return <chatBubbleAppContext.Provider value={chatBubbleValue}>{children}</chatBubbleAppContext.Provider>;
};

const useChatBubbleApp = () => {
	const context = useContext(chatBubbleAppContext);

	if (context === undefined) {
		throw new Error(`useChatBubbleApp must be used within a ChatBubbleAppProvide`);
	}

	return context;
};

const useChatBubbleAppProvide = () => {
	const WS_API_URL = 'wss://ws.synthagents.ai';
	const clientType = 'guest';
	const botInfoObj = useRef(null);

	// botInfoObj.current = document.querySelector('script[data-name="synth-chat-bubble"]');

	useEffect(() => {
		const scriptElement = document.querySelector('script[data-name="synth-chat-bubble"]');

		if (scriptElement) {
			botInfoObj.current = scriptElement;
		}
	}, []);

	// console.log('botInfoObj', botInfoObj.current);

	// const botInfoObj = {
	// 	id: 'kwdmxzqk8ycswab9vve9epir',
	// 	dataName: 'synth-chat-bubble',
	// 	src: 'https://www.synthagents.ai/assets/libs/chat-bubble.js'
	// };

	const closeTimeout = useRef();
	const chatId = useRef(generateKey(24));
	const shouldConnect = useRef(false);
	const requestKey = useRef(null);
	const mkId = useRef(null);

	const [messageSending, setMessageSending] = useState(false);

	const [botAgentId, setBotAgentId] = useState('');
	const [chatMessages, setChatMessages] = useState([]);
	const [isConnectionActive, setIsConnectionActive] = useState(false);

	const [guessChatWith, setGuessChatWith] = useState('guestChatWithAgent');

	const [agentState, setAgentState] = useState(AGENT_INITIAL_STATE);
	const [chatLocalState, setChatLocalState] = useState(chatBubbleInitialState);
	const [bubbleState, setBubbleState] = useState(BUBBLE_INITIAL_STATE);

	const [bubbleAppearanceState, setBubbleAppearanceState] = useState(defaultChatBubbleAppearance);

	const [storedSettings, setStoredSettings] = useSessionStorage(
		'settings',
		{}, // Default value
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			encode: (value) => {
				try {
					if (value === undefined || value === null) {
						return Base64.encode(JSON.stringify({}));
					}

					if (typeof value !== 'object') {
						console.warn('Encoding received non-object value:', typeof value);
						return Base64.encode(JSON.stringify({ value }));
					}

					const stringified = JSON.stringify(value);
					return Base64.encode(stringified);
				} catch (error) {
					console.error('Error encoding value:', error);
					return Base64.encode(JSON.stringify({}));
				}
			},
			decode: (text) => {
				try {
					if (!text) return {};

					const decoded = Base64.decode(text);
					return JSON.parse(decoded);
				} catch (error) {
					console.error('Error decoding stored settings:', error);
					return {};
				}
			}
		}
	);

	useEffect(() => {
		console.log('Current storedSettings:', storedSettings);
	}, [storedSettings]);

	useEffect(() => {
		console.log('chatLocalState:', chatLocalState);
	}, [chatLocalState]);

	const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
		WS_API_URL,
		{
			protocols: ['Authorization', `mkId.${mkId.current}`, `botAgentId.${botAgentId}`],
			share: true,
			retryOnError: true,
			// filter: message => {
			//   let messageData = JSON.parse(message.data);
			//   if (messageData?.requestKey === requestKey.current) {
			//     return true;
			//   }
			// },
			onReconnectStop: () => {
				console.log('onReconnectStop');
			},
			onOpen: () => {
				console.log('onOpen');

				// handelJoinRoom(chatId);
				// always clearTimeout if one exists on new connection
				if (closeTimeout.current) clearTimeout(closeTimeout.current);

				closeTimeout.current = setTimeout(() => {
					if (getWebSocket().readyState === WebSocket.OPEN) {
						// Instead of closing directly, we deactivate the connection
						// setIsConnectionActive(false);
						// Optionally, you can directly instruct the server to close the connection
						// sendJsonMessage({ action: 'close' });
					}
				}, 6 * 1000);
			},
			// Always reconnect
			shouldReconnect: (_closeEvent) => true
		},
		isConnectionActive
	);

	const handleOtpRequest = useCallback((name, email) => {
		console.log('handleOtpRequest 123');
		setChatLocalState((prevState) => ({
			...prevState,
			contactDetails: { ...prevState.contactDetails, name, email }
		}));

		console.log('readyState', readyState);
		return sendJsonMessage({
			action: 'serviceOtpRequest',
			mkId: mkId.current,
			name,
			email,
			requestKey: requestKey.current
		});
	}, []);

	const handleOtpVerify = useCallback(
		(email, otp) =>
			sendJsonMessage({
				action: 'serviceOtpVerify',
				mkId: mkId.current,
				otp,
				email,
				requestKey: requestKey.current
			}),
		[mkId.current]
	);

	const handleFeedback = useCallback(
		(ratings, reason) =>
			sendJsonMessage({
				action: 'chatMessageFeedback',
				chatId: chatId.current,
				agentId: botAgentId,
				mkId: mkId.current,
				feedbackType: 'chat_overall_feedback',
				feedbackValue: { ratings, reason },
				requestKey: requestKey.current
			}),
		[botAgentId, mkId.current, sendJsonMessage]
	);

	const handelJoinRoom = useCallback((accountId) => {
		setBubbleState((prevState) => ({
			...prevState,
			roomJoined: true,
			botOnline: false,
			botStatus: 'loading'
		}));

		return sendJsonMessage({
			action: 'chatJoin',
			chatId: chatId.current,
			botAgentId: botInfoObj.current.id,
			mkId: mkId.current,
			clientType,
			requestKey: requestKey.current
		});
	}, []);

	const handelExitRoom = useCallback((accountId) => {
		setBubbleState((prevState) => ({
			...prevState,
			roomJoined: false
		}));

		return sendJsonMessage({
			action: 'chatExit',
			chatId: chatId.current,
			// accountId: accountId,
			clientType
		});
	}, []);

	const handleSendMessage = useCallback((userQuery, action = guessChatWith) => {
		// Send chat message to agent.
		setMessageSending(true);

		setBubbleState((prevState) => ({
			...prevState,
			showBotThinking: true
		}));

		// Set chat message in UI
		const nextMessageId = generateKey(36);
		setChatMessages((chatMessages) => [
			...chatMessages,
			{
				id: nextMessageId.toString(),
				sender: 'You',
				content: userQuery,
				timestamp: DateTime.now().toUnixInteger()
			}
		]);

		sendJsonMessage({
			action,
			chatId: chatId.current,
			messageId: nextMessageId,
			requestKey: requestKey.current,
			query: userQuery
		});
	}, []);

	const handleChangeChatWith = (agentType) => {
		console.log('Update agent type', agentType);
		// setChatWithType(chatWith[agentType]);
	};

	useEffect(() => {
		if (botInfoObj.current && botInfoObj.current.id) {
			setBotAgentId(botInfoObj.current.id);

			sendJsonMessage({
				action: 'assetData',
				assetType: 'agent',
				assetId: botInfoObj.current.id,
				dataType: 'agentDetail',
				requestKey: requestKey.current
			});
		}
	}, [botInfoObj.current]);

	useEffect(() => {
		if (mkId.current) {
			sendJsonMessage({
				action: 'assetData',
				assetType: 'contact',
				assetId: mkId.current,
				dataType: 'contactDetail',
				requestKey: requestKey.current
			});
		}
	}, [mkId.current]);

	useEffect(() => {
		console.log('messageSending', messageSending);
		console.log('chatMessages', chatMessages);
	}, [messageSending]);

	useEffect(() => {
		if (botInfoObj.current?.id && mkId.current) {
			setIsConnectionActive(true);
			sendJsonMessage({
				action: 'assetData',
				assetType: 'contact',
				assetId: mkId.current,
				dataType: 'chatList',
				botId: botInfoObj.current?.id,
				requestKey: requestKey.current
			});
			// sendJsonMessage({
			// 	action: 'assetData',
			// 	assetType: 'contact',
			// 	assetId: mkId.current,
			// 	dataType: 'chatMessageList',
			// 	botId: botInfoObj.current?.id,
			// 	requestKey: requestKey.current
			// });
		}
	}, [botInfoObj.current, mkId.current]);

	useEffect(() => {
		const identifyUser = async () => {
			try {
				console.log('identifyUser called, current storedSettings:', storedSettings);

				if (storedSettings && storedSettings._mkId) {
					console.log('Existing mkId found:', storedSettings._mkId);
					mkId.current = storedSettings._mkId;
				} else {
					console.log('No existing mkId, generating new one');
					const fp = await fpPromise;
					const result = await fp.get();
					console.log('Generated new mkId:', result.visitorId);
					mkId.current = result.visitorId;
					setStoredSettings({ ...storedSettings, _mkId: result.visitorId });
				}

				shouldConnect.current = true;
			} catch (error) {
				console.error('Error in identifyUser:', error);
			}
		};

		identifyUser();
	}, []);

	useLayoutEffect(() => {
		requestKey.current = generateKey(12);
	}, []);

	useEffect(() => {
		console.log('lastJsonMessage', lastJsonMessage);

		if (lastJsonMessage) {
			if (lastJsonMessage?.service) {
				if (lastJsonMessage?.service === 'otpRequest') {
					if (lastJsonMessage?.status === 'requested') {
						setChatLocalState((prevState) => ({
							...prevState,
							twoFa: { ...prevState.twoFa, send: true }
						}));
					}
				} else if (lastJsonMessage?.service === 'otpVerify') {
					if (lastJsonMessage?.status === 'passed') {
						setChatLocalState((prevState) => ({
							...prevState,
							twoFa: { ...prevState.twoFa, verified: true }
						}));
					}
				} else if (lastJsonMessage?.service === 'agentHangOver') {
					const nextAgent = lastJsonMessage?.agentNext ? lastJsonMessage.agentNext : 'guestChatWithAgent';

					setGuessChatWith(nextAgent);
				}
			} else if (lastJsonMessage?.dataRequest) {
				if (lastJsonMessage?.dataRequest === 'agentDetail') {
					setBubbleState((prevState) => ({
						...prevState,
						// agent: { ...bubbleState.agent, name: lastJsonMessage.name },
						hasAgenDetailsLoaded: true
					}));

					setAgentState((prevState) => ({
						...prevState,
						id: botInfoObj.current?.id,
						avatar: lastJsonMessage?.appearance?.avatar,
						name: _.startCase(lastJsonMessage?.name),
						description: lastJsonMessage?.description,
						initialMessage: lastJsonMessage?.initialMessage,
						quickPrompts: lastJsonMessage?.quickPrompts
					}));

					setBubbleAppearanceState((prevState) => ({
						...prevState,
						...lastJsonMessage.appearance
					}));
				} else if (lastJsonMessage?.dataRequest === 'contactDetail') {
					setChatLocalState((prevState) => ({
						...prevState,
						twoFa: { ...prevState.twoFa, verified: lastJsonMessage?.otpVerified },
						contactDetails: { ...prevState.contactDetails, ...lastJsonMessage?.details }
					}));
				} else if (lastJsonMessage?.dataRequest === 'chatList') {
					setChatLocalState((prevState) => ({
						...prevState,
						messageList: lastJsonMessage?.chatList
					}));
				}

				// console.log('dataRequest', lastJsonMessage);
			} else if (lastJsonMessage?.subAction) {
				/*
				 * We can change from GPT to Agent chat
				 */
				if (lastJsonMessage.subAction === 'agentTypeChange') {
					/*
					 * We can change from GPT to Agent chat
					 */
					handleChangeChatWith(lastJsonMessage.agentType);
				} else if (lastJsonMessage.subAction === 'agentJoinsRoom') {
					/*
					 * Agent has entered the chat and is available to answer questions.
					 */
					setBubbleState((prevState) => ({
						...prevState,
						botOnline: true,
						showBotThinking: true
					}));
				} else if (lastJsonMessage.subAction === 'agentExitsRoom') {
					/*
					 * Agent has entered the chat and is available to answer questions.
					 */
					// setBubbleState(prevState => ({
					//   ...prevState,
					//   botOnline: false,
					//   botStatus: null,
					//   showBotThinking: false,
					//   chatBotPanelActive: 'feedback',
					// }));
				}

				console.log('subAction');
			} else if (lastJsonMessage?.action) {
				console.log('action');

				if (lastJsonMessage?.action === 'gptChatWithGuest') {
					console.log('action gptChatWithGuest');

					if (!lastJsonMessage.hasOwnProperty('debugData')) {
						setMessageSending(false);

						setBubbleState((prevState) => ({
							...prevState,
							showBotThinking: false
						}));

						setChatMessages((chatMessages) => [
							...chatMessages,
							{
								id: generateKey(36),
								chatId: '',
								contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
								content: lastJsonMessage?.answer,
								// timestamp: lastJsonMessage?.timestamp,
								// This should come from the message.
								timestamp: DateTime.utc().toSeconds(),
								createdAt: lastJsonMessage?.createdAt,
								sender: {
									name: lastJsonMessage?.agent,
									username: '@melissa',
									avatar: '/static/images/avatar/7.jpg',
									online: bubbleState.botOnline
								}
							}
						]);
					}
				} else if (lastJsonMessage?.action === 'agentChatWithGuest') {
					console.log('action agentChatWithGuest');
					setMessageSending(false);

					setBubbleState((prevState) => ({
						...prevState,
						showBotThinking: false
					}));

					setChatMessages((chatMessages) => [
						...chatMessages,
						{
							id: generateKey(36),
							chatId: '',
							contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
							content: lastJsonMessage?.answer,
							// timestamp: lastJsonMessage?.timestamp,
							// This should come from the message.
							timestamp: DateTime.utc().toSeconds(),
							// createdAt: DateTime.utc().toSeconds(),
							sender: {
								name: lastJsonMessage?.agent,
								username: '@melissa',
								avatar: '/static/images/avatar/7.jpg',
								online: bubbleState.botOnline
							}
						}
					]);
				}
			}
		}
	}, [lastJsonMessage]);

	return {
		handleOtpRequest,
		handleOtpVerify,
		handelJoinRoom,
		handleFeedback,
		handleSendMessage,
		setAgentState,
		agentState,
		bubbleAppearanceState,
		bubbleState,
		setBubbleState,
		chatMessages,
		chatLocalState,
		setBubbleAppearanceState,
		setChatLocalState,
		setChatMessages,
		lastJsonMessage
	};
};

const ChatBubbleAppReadOnlyProvide = ({ children }) => {
	const chatBubbleValue = useChatBubbleAppReadOnlyProvide();

	return <chatBubbleAppContext.Provider value={chatBubbleValue}>{children}</chatBubbleAppContext.Provider>;
};

const useChatBubbleAppReadOnlyProvide = () => {
	const chatKey = generateKey(24);
	// let clientType = 'guest';

	const botInfoObj = useRef(null);

	botInfoObj.current = document.querySelector('script[data-name="synth-chat-bubble"]');

	// This is user detail info, coming from the authed user.
	const chatBubbleInitialState = {
		messageList: [{ message: 'message' }, { message: 'message' }, { message: 'message' }, { message: 'message' }],
		// contactDetails: { name: 'Json Born', email: 'jason.born@gmail.com' },
		contactDetails: {},
		twoFa: { send: false, verified: false, otpSet: false }
	};

	const closeTimeout = useRef();
	const chatId = useRef(chatKey);
	const shouldConnect = useRef(false);
	const requestKey = useRef(null);

	const [messageSending, setMessageSending] = useState(false);

	const [botAgentId, setBotAgentId] = useState('');
	const [chatMessages, setChatMessages] = useState([]);
	const [isConnectionActive, setIsConnectionActive] = useState(false);
	const [mkId, setMkId] = useState('');

	const [chatLocalState, setChatLocalState] = useState(chatBubbleInitialState);

	const [agentState, setAgentState] = useState(AGENT_INITIAL_STATE);

	const [bubbleState, setBubbleState] = useState({
		...BUBBLE_INITIAL_STATE,
		isOpen: true,
		chatBotPanelActive: 'active'
	});

	const [bubbleAppearanceState, setBubbleAppearanceState] = useState({
		...defaultChatBubbleAppearance
	});

	const handleOtpRequest = useCallback(
		(name, email) => {
			setChatLocalState((prevState) => ({
				...prevState,
				contactDetails: { ...prevState.contactDetails, name, email }
			}));
		},
		[mkId, setChatLocalState]
	);

	const handleFeedback = useCallback(
		(ratings, reason) => {
			console.log('ratings, reason', ratings, reason);
		},
		[mkId]
	);
	const handleOtpVerify = useCallback(
		(email, otp) => {
			console.log(email, otp);
		},
		[mkId]
	);

	const handelJoinRoom = useCallback(() => {
		console.log('handelJoinRoom');
	}, []);

	const handelExitRoom = useCallback(() => {}, []);

	const handleSendMessage = useCallback((userQuery, action = 'guestChatWithAgent') => {
		// Set chat message in UI
		const nextMessageId = generateKey(36);
		setChatMessages((chatMessages) => [
			...chatMessages,
			{
				id: nextMessageId.toString(),
				sender: 'You',
				content: userQuery,
				timestamp: DateTime.now().toUnixInteger()
			}
		]);

		// Send chat message to agent.
		setMessageSending(true);
	}, []);

	const handleChangeChatWith = (agentType) => {
		console.log('Update agent type', agentType);
		// setChatWithType(chatWith[agentType]);
	};

	useEffect(() => {
		if (botInfoObj.current?.id) {
			setBotAgentId(botInfoObj.current.id);
		}
	}, [botInfoObj.current]);

	useEffect(() => {
		// console.log('chatLocalState', chatLocalState);
	}, [chatLocalState]);

	useEffect(() => {
		if (mkId) {
		}
	}, [mkId]);

	useEffect(() => {
		console.log('messageSending', messageSending);
		console.log('chatMessages', chatMessages);
	}, [messageSending]);

	useEffect(() => {
		if (botInfoObj.current?.id && mkId) {
			setIsConnectionActive(true);
		}
	}, [botInfoObj.current, mkId]);

	useLayoutEffect(() => {
		// Calculate visitor id
		const identifyUser = async () => {
			// Get the visitor identifier when you need it.
			const fp = await fpPromise;
			const result = await fp.get();
			setMkId(result.visitorId);
			shouldConnect.current = true;
			// setStoredSettings({ ...storedSettings, _mkId: result.visitorId });
		};

		identifyUser().catch(console.error);
	}, []);

	useLayoutEffect(() => {
		requestKey.current = generateKey(12);
	}, []);

	return {
		handleOtpRequest,
		handleOtpVerify,
		handelJoinRoom,
		handleFeedback,
		handleSendMessage,
		bubbleAppearanceState,
		setAgentState,
		agentState,
		bubbleState,
		chatMessages,
		chatLocalState,
		setBubbleState,
		setBubbleAppearanceState,
		setChatLocalState,
		setChatMessages
	};
};

export { ChatBubbleAppProvide, useChatBubbleAppProvide, ChatBubbleAppReadOnlyProvide, useChatBubbleApp };
