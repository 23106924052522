import { useChatBubbleApp } from './context/useChatBubble';
import ChatBoxTemplate from './components/ChatBoxTemplate';
import { extendTheme } from '@mui/joy/styles';
import colors from '@mui/joy/colors';

export const theme = extendTheme({
	cssVarPrefix: 'synth-chat-bubble',
	colorSchemes: {
		dark: {
			palette: {
				primary: colors.yellow,
				contrastText: '#FFFFFF'
			}
		},
		light: {
			palette: {
				primary: colors.green,
				contrastText: '#FFFFFF'
			}
		}
	}
});

const ChatBubbleApp = (props) => {
	const { chatBoxCss } = props;

	const {
		chatLocalState,
		agentState,
		bubbleState,
		bubbleAppearanceState,
		chatMessages,
		setBubbleAppearanceState,
		setBubbleState,
		handleSendMessage,
		handelJoinRoom,
		handleOtpVerify,
		handleFeedback,
		handleOtpRequest
	} = useChatBubbleApp();

	return (
		<ChatBoxTemplate
			agent={agentState}
			chatBoxCss={chatBoxCss}
			chatLocalState={chatLocalState}
			bubbleAppearanceState={bubbleAppearanceState}
			setBubbleAppearanceState={setBubbleAppearanceState}
			bubbleState={bubbleState}
			setBubbleState={setBubbleState}
			chatMessages={chatMessages}
			handelJoinRoom={handelJoinRoom}
			handleSendMessage={handleSendMessage}
			handleFeedback={handleFeedback}
			handleOtpVerify={handleOtpVerify}
			handleOtpRequest={handleOtpRequest}
		/>
	);
};

export default ChatBubbleApp;
