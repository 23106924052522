import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import createCache from '@emotion/cache';
import {
  CssVarsProvider,
  extendTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/joy/styles';

import CssBaseline from '@mui/joy/CssBaseline';
import { CacheProvider } from '@emotion/react';

import ChatBubbleApp from '../../../components/ChatBoxTemplate/ChatBubbleApp';

import { ChatBubbleAppProvide } from '../../../../../src/app/components/ChatBoxTemplate/context/useChatBubble';

import colors from '@mui/joy/colors';

export const theme = extendTheme({
  cssVarPrefix: 'synth-chat-bubble',
  colorSchemes: {
    dark: {
      palette: {
        primary: colors.yellow,
        contrastText: '#FFFFFF',
      },
    },
    light: {
      palette: {
        primary: colors.green,
        contrastText: '#FFFFFF',
      },
    },
  },
});

const chatBoxCss = {
  chatButton: {
    bottom: '80px',
    left: '20px',
    position: 'fixed',
    right: '20px',
  },
  containerBox: {
    bottom: '70px',
    position: 'fixed',
  },
};

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-restricted-globals
  addEventListener('DOMContentLoaded', event => {
    const cache = createCache({
      key: 'chat-bubble',
      prepend: true,
      speedy: true,
    });

    const me = document.querySelector('script[data-name="synth-chat-bubble"]');

    if (!me?.id) {
      return;
    }

    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = createRoot(div);

    root.render(
      <StrictMode>
        <StyledEngineProvider injectFirst>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <CssVarsProvider
                theme={theme}
                defaultMode="light"
                modeStorageKey="synth-chat-bubble"
                colorSchemeStorageKey="synth-chat-bubblescheme"
              >
                <ChatBubbleAppProvide>
                  <ChatBubbleApp chatBoxCss={chatBoxCss} />
                </ChatBubbleAppProvide>
              </CssVarsProvider>
            </ThemeProvider>
          </CacheProvider>
        </StyledEngineProvider>
      </StrictMode>,
    );
  });
}
