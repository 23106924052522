import { useCallback, useMemo, memo } from 'react';
import Chip from '@mui/joy/Chip';
import Stack from '@mui/joy/Stack';
import { debounce } from 'lodash';

const MessageTemplates = (props) => {
	const { quickPrompts, handleSendMessage } = props;

	console.log('quickPrompts', quickPrompts);
	// Create a memoized version of the debounced function
	const debouncedHandleTemplateClick = useMemo(
		() =>
			debounce((prompt) => {
				handleSendMessage(prompt, 'guestChatWithGptAgent');
			}, 300), // 300ms delay
		[handleSendMessage]
	);

	// Wrap the debounced function in useCallback
	const handleTemplateClick = useCallback(
		(prompt) => {
			debouncedHandleTemplateClick(prompt);
		},
		[debouncedHandleTemplateClick]
	);

	return (
		<div className="absolute inset-x-0 bottom-0">
			<Stack
				justifyContent="flex-end"
				alignItems="flex-end"
				direction="row"
				flexWrap="wrap"
				spacing={1}
				useFlexGap
			>
				{quickPrompts &&
					quickPrompts.map((promptItem) => (
						<Chip
							onClick={() => handleTemplateClick(promptItem.prompt)}
							key={promptItem.title}
							variant="outlined"
							size="lg"
						>
							{promptItem.title}
						</Chip>
					))}
			</Stack>
		</div>
	);
};

export default MessageTemplates;
