import { useEffect, useState } from 'react';
import IconButton from '@mui/joy/IconButton';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Tooltip from '@mui/joy/Tooltip';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';

const SAActionLogo = 'https://www.synthagents.ai/assets/libs/synthagents_favicon.svg'

const brandColorMinor = '#4D4D4D';
const brandColorMajor = '#006987';

const FloatingButton = props => {
  const {
    customActionButton,
    hideToolTip,
    setBubbleState,
    isOpen,
    colorPrimary,
    textColor,
    welcomeMessage,
  } = props;

  // const customActionButton = false;
  // const hideToolTip = false;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (setBubbleState?.hasOpenOnce) {
        setShowTooltip(false);
      } else if (hideToolTip) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    }, 2000); // 5000 milliseconds = 5 seconds

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [hideToolTip]); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <IconButton
        color="inherit"
        variant="solid"
        onClick={() =>
          setBubbleState(prevState => ({
            ...prevState,
            isOpen: !isOpen,
            ...(!isOpen
              ? {
                  hasOpenOnce: true,
                }
              : {}),
          }))
        }
        sx={theme => ({
          backgroundColor: customActionButton ? colorPrimary : brandColorMinor,
          width: '60px',
          height: '60px',
          borderRadius: '100%',
          color: textColor,
          transition: 'all 100ms ease-in-out',

          '&:hover': {
            backgroundColor: customActionButton
              ? colorPrimary
              : brandColorMinor,
            filter: 'brightness(0.9)',
            transform: 'scale(1.05)',
          },
        })}
      >
        {isOpen ? (
          <>
            {customActionButton ? (
              <ClearRoundedIcon
                sx={{
                  backgroundColor: colorPrimary,
                }}
              />
            ) : (
              <ClearRoundedIcon
                sx={{
                  backgroundColor: brandColorMinor,
                  '&:hover': {
                    backgroundColor: customActionButton
                      ? colorPrimary
                      : brandColorMinor,
                    filter: 'brightness(0.9)',
                    transform: 'scale(1.05)',
                  },
                }}
              />
            )}
          </>
        ) : (
          <Tooltip
            arrow
            title={welcomeMessage}
            variant="outlined"
            // open={hideToolTip ? false : undefined}
            open={showTooltip}
            size="lg"
            placement="top-start"
            // enterDelay={1000}
          >
            <Box
              sx={{
                padding: '26px',
                // backgroundColor: 'rgba(159,213,157,0.22)',
              }}
            >
              {customActionButton ? (
                <Avatar
                  sx={{
                    backgroundColor: colorPrimary,
                    height: '4rem',
                    width: '4rem',
                    padding: '.5rem',
                  }}
                  // className="h-16 w-16 p-2"
                  size="lg"
                  src={customActionButton}
                />
              ) : (
                <Avatar
                  sx={{
                    backgroundColor: brandColorMinor,
                    height: '4rem',
                    width: '4rem',
                    padding: '.5rem',
                  }}
                  // className="h-16 w-16 p-2"
                  size="lg"
                  src={SAActionLogo}
                />
              )}
            </Box>
          </Tooltip>
        )}
      </IconButton>
    </div>
  );
};

export default FloatingButton;

