export default function generateKey(len = 5, lowerCase = false) {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

	for (let i = 0; i < len; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	if (lowerCase) {
		return text.toLowerCase();
	}

	return text;
}
