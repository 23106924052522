import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFaceGrinHearts,
	faFaceRaisedEyebrow,
	faFaceUnamused,
	faFaceSadTear,
	faFaceSmile
} from '@fortawesome/pro-duotone-svg-icons';
import { useTheme } from '@mui/material/styles';

const FeedBackIcon = ({ iconName }) => {
	const theme = useTheme();
	return (
		<>
			{(() => {
				switch (iconName) {
					case 'poor':
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									// '--fa-primary-color': theme.palette.primary[600],
									// '--fa-secondary-color': theme.palette.primary[300]
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceSadTear}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
					case 'below avg':
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceUnamused}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
					case 'okay':
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceRaisedEyebrow}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
					case 'good':
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceSmile}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
					case 'amazing':
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceGrinHearts}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
					default:
						return (
							<FontAwesomeIcon
								style={{
									// '--fa-secondary-opacity': '2.0',
									'--fa-primary-color': '#FDD835',
									'--fa-secondary-color': '#bc0909'
								}}
								icon={faFaceRaisedEyebrow}
								size={'2xl'}
								// rotation={90}
								// flip
							/>
						);
				}
			})()}
		</>
	);
};

export default FeedBackIcon;
