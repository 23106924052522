import { styled } from '@mui/joy/styles';

const StyledMessageRow = styled('div')(
	({ colorPrimary, colorPrimaryContrastText, colorSecondary, colorSecondaryContrastText }) => ({
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		flexShrink: '0',
		flexGrow: '0',
		width: '368px',
		alignItems: 'flex-start',
		paddingX: '1.6rem',
		paddingBottom: '0.4rem',
		'&.chat-block-bottom': { paddingBottom: '4rem' },
		'&.contact': {
			'& .bubble': {
				backgroundColor: colorSecondary,
				color: colorSecondaryContrastText,
				borderTopLeftRadius: 5,
				borderBottomLeftRadius: 5,
				borderTopRightRadius: 20,
				borderBottomRightRadius: 20,
				'& .time': {
					marginLeft: 12,
					color: '#676666'
				}
			},
			'&.first-of-group': {
				'& .bubble': {
					borderTopLeftRadius: 20,
					marginTop: '2rem'
				}
			},
			'&.last-of-group': {
				'& .bubble': {
					borderBottomLeftRadius: 20
				}
			}
		},
		'&.me': {
			paddingLeft: 40,

			'& .bubble': {
				marginLeft: 'auto',
				backgroundColor: colorPrimary,
				color: colorPrimaryContrastText,
				borderTopLeftRadius: 20,
				borderBottomLeftRadius: 20,
				borderTopRightRadius: 5,
				borderBottomRightRadius: 5,
				'& .time': {
					justifyContent: 'flex-end',
					right: 0,
					marginRight: 12,
					color: '#676666'
				}
			},
			'&.first-of-group': {
				'& .bubble': {
					borderTopRightRadius: 20,
					marginTop: '2rem'
				}
			},

			'&.last-of-group': {
				'& .bubble': {
					borderBottomRightRadius: 20
				}
			}
		},
		'&.contact + .me, &.me + .contact': {
			paddingTop: 20,
			marginTop: 20
		},
		'&.first-of-group': {
			'& .bubble': {
				borderTopLeftRadius: 20,
				paddingTop: 13
			}
		},
		'&.last-of-group': {
			'& .bubble': {
				borderBottomLeftRadius: 20,
				paddingBottom: 13,
				'& .time': {
					display: 'flex'
				}
			}
		}
	})
);

export default StyledMessageRow;
