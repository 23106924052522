import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import SendIcon from '@mui/icons-material/Send';

const SubPanelMessagesNoMessage = (props) => {
	const { bubbleState, setBubbleState } = props;
	return (
		<Stack
			sx={{
				width: '100%',
				maxHeight: '480px',
				minHeight: '380px'
			}}
		>
			<span>No Messages</span>
			<Button
				onClick={() =>
					setBubbleState((prevState) => ({
						...prevState,
						chatBotPanelActive: 'active'
					}))
				}
				endDecorator={<SendIcon />}
			>
				{bubbleState?.botOnline ? 'Continue' : 'Start'} Conversation
			</Button>
		</Stack>
	);
};

const MessageItem = (props) => {
	const { message } = props;
	return <Stack className="">{message.value}</Stack>;
};
const SubPanelMessagesList = (props) => {
	const { messageList = [], bubbleState, setBubbleState } = props;

	return (
		<Stack
			sx={{
				width: '100%',
				maxHeight: '480px',
				minHeight: '380px'
			}}
			// className="bg-cyan-50"
		>
			{messageList.map((message) => (
				<MessageItem message={message}/>
			))}
			<div className='absolute bottom-0 right-0 flex w-full  justify-center'>
				<Button
					variant="solid"
					onClick={() => setBubbleState((prevState) => ({...prevState, isChatBotActive: true}))}
					endDecorator={<SendIcon/>}
				>
					{bubbleState?.botOnline ? 'Continue' : 'Start'} Conversation
				</Button>
				</div>
		</Stack>
);
};

const PanelMessages = (props) => {
	// const { chatLocalState, setBubbleState } = useChatBubbleApp();

	const { userName, customActionButton, primaryColor, chatLocalState, bubbleState, setBubbleState } = props;

	return (
		<Box
			className="relative"
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={(theme) => ({
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				position: 'relative',
				maxHeight: '480px',
				minHeight: '380px',
				overflow: 'auto',

				[theme.breakpoints.down('sm')]: {
					height: '80vh',
					maxHeight: '80vh',
					maxWidth: '100vw'
				}
			})}
		>
			<Stack
				className=""
				sx={{
					width: '100%'
				}}
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				{chatLocalState?.messageList && chatLocalState.messageList.length > 0 ? (
					<SubPanelMessagesList
						messageList={chatLocalState.messageList}
						bubbleState={bubbleState}
						setBubbleState={setBubbleState}
					/>
				) : (
					<SubPanelMessagesNoMessage
						bubbleState={bubbleState}
						setBubbleState={setBubbleState}
					/>
				)}
			</Stack>
		</Box>
	);
};

export default PanelMessages;
